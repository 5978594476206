import { classNames } from "../utils";

class {
  onCreate(){
    this.state = { isCollapsed: true, isClamped: true };
  }

  toggle(_, el) {
    const answerEl = el.querySelector('p');
    const { isCollapsed } = this.state
    
    answerEl.style.maxHeight = isCollapsed ? answerEl.scrollHeight + "px" : 0;
    this.state.isCollapsed = !isCollapsed;
  }

  onLearnMoreClick(e, el){
    // e.stopPropagation();
    // this.state.isClamped = false;
    // el.parentElement.style.maxHeight = 'max-content';
  }
}

$ const { question, answer } = input.data;

<div class=classNames('faqItem', {collapsed: state.isCollapsed, clamped: state.isClamped}) on-click('toggle') >
  <h4>${question}</h4>
  <p>
    <span>${answer}</span>
    <!-- <button class="learn-more" on-click('onLearnMoreClick')>Learn more</button> -->
  </p>
</div>